import { useEffect, useMemo, useState } from 'react'
import {
  Link,
  Navigate,
  NavLink,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  BookOpenIcon,
  CalendarIcon,
  ChatBubbleOvalLeftIcon,
  ChartBarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  HomeIcon,
  UsersIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useAuth0 } from '@auth0/auth0-react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { cn } from '~/utils/cn'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { LogoutAlert } from './_components/logout-alert'
import { TimeZoneChangeAlert } from './_components/time-zone-change-dialog'
import { TroubleshootDialog } from '~/components/troubleshoot-dialog'
import { USING_V2 } from '~/app/constants'
import { useUserDataWithAuth0Token } from '~/hooks/useUserDataWithAuth0Token'
import { saveUserToken } from '~/features/auth/authSlice'

const navigation = [
  { name: 'Home', href: USING_V2 ? '/v2' : '/', icon: HomeIcon },
  {
    name: 'Book a session',
    href: USING_V2 ? '/v2/book' : '/book',
    icon: UsersIcon,
  },
  {
    name: 'Chat with coach',
    href: USING_V2 ? '/v2/chat' : '/chat',
    icon: ChatBubbleOvalLeftIcon,
  },
  {
    name: 'Sessions',
    href: USING_V2 ? '/v2/bookings' : '/bookings',
    icon: CalendarIcon,
  },
  {
    name: 'Hupo perspectives',
    href: USING_V2 ? '/v2/hupo-perspectives' : '/hupo-perspectives',
    icon: ChartBarIcon,
    whenHupoPerspectivesEnabled: true,
  },
  {
    name: 'Assessments',
    href: USING_V2 ? '/v2/assessment-results' : '/assessment-results',
    icon: ChartBarIcon,
    whenHupoPerspectivesEnabled: false,
  },
  {
    name: 'Notes',
    href: USING_V2 ? '/v2/notes' : '/notes',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'My progress',
    href: USING_V2 ? '/v2/progress' : '/progress',
    icon: ChartPieIcon,
  },
  {
    name: 'Resources',
    href: USING_V2 ? '/v2/resources' : '/resources',
    icon: BookOpenIcon,
  },
]
const support = [
  {
    id: 'help-center',
    name: 'Help center',
    href: '#',
    action(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault()
      window._chatlio.open()
    },
    icon: QuestionMarkCircleIcon,
    current: false,
  },
  {
    id: 'troubleshooting',
    name: 'Troubleshooting',
    icon: ExclamationTriangleIcon,
    action(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault()
    },
    current: false,
  },
]
const userNavigation = [
  {
    name: 'Your profile',
    href: USING_V2 ? '/v2/settings/account' : '/settings/account',
  },
  {
    name: 'Change coach',
    href: USING_V2 ? '/v2/change-coach' : '/change-coach',
  },
  { name: 'User agreement', href: 'https://main.hupo.co/core/user-agreement' },
  { name: 'Sign out', action: 'logout' },
]

export const AppShell: React.FC = () => {
  const { user, auth0Token, token } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { getAccessTokenSilently } = useAuth0()
  const { getUserDataWithAuth0Token } = useUserDataWithAuth0Token()
  const [searchParams] = useSearchParams()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [troubleshootModalOpen, setTroubleshootModalOpen] = useState(false)
  const [logoutAlertOpen, setLogoutAlertOpen] = useState(false)

  const fromAuth0 = useMemo(
    () => searchParams.get('fromAuth0') === 'true',
    [searchParams]
  )

  const isChatPage = useMemo(
    () => location.pathname.endsWith('/chat'),
    [location.pathname]
  )

  const hasToken = useMemo(
    () => Boolean(auth0Token || token),
    [auth0Token, token]
  )
  const hupoPerspectivesEnabled = useMemo(() => {
    return !!(user?.values && user.values.length > 0)
  }, [user])

  const navItems = useMemo(() => {
    return navigation.filter(
      (item) =>
        !(typeof item.whenHupoPerspectivesEnabled === 'boolean') ||
        item.whenHupoPerspectivesEnabled === hupoPerspectivesEnabled
    )
  }, [hupoPerspectivesEnabled])

  useEffect(() => {
    if (fromAuth0) {
      getAccessTokenSilently().then((token) => {
        dispatch(saveUserToken(token, 'auth0'))
        getUserDataWithAuth0Token(token)
      })
    }
  }, [getAccessTokenSilently, dispatch, getUserDataWithAuth0Token, fromAuth0])

  if (!hasToken) return <Navigate to="/login" />

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Hupo"
                    src="/logos/non-trademarked/orange/logo.png"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navItems.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              className={({ isActive }) =>
                                cn(
                                  isActive
                                    ? 'bg-gray-50 text-primary'
                                    : 'text-gray-700 hover:bg-gray-50 hover:text-primary',
                                  'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                )
                              }
                            >
                              {({ isActive }) => (
                                <>
                                  <item.icon
                                    aria-hidden="true"
                                    className={cn(
                                      isActive
                                        ? 'text-primary'
                                        : 'text-gray-400 group-hover:text-primary',
                                      'h-6 w-6 shrink-0'
                                    )}
                                  />
                                  {item.name}
                                </>
                              )}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Support
                      </div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {support.map((item) => (
                          <li key={item.id}>
                            <a
                              href={item.href}
                              onClick={
                                item.id === 'troubleshooting'
                                  ? () => setTroubleshootModalOpen(true)
                                  : item.action
                              }
                              className={cn(
                                item.current
                                  ? 'bg-gray-50 text-primary'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-primary',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={cn(
                                  item.current
                                    ? 'text-primary'
                                    : 'text-gray-400 group-hover:text-primary',
                                  'h-6 w-6 shrink-0'
                                )}
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <Link
                        to={
                          USING_V2
                            ? '/v2/settings/account'
                            : '/settings/account'
                        }
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary"
                      >
                        <Cog6ToothIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary"
                        />
                        Settings
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Hupo"
                src="/logos/non-trademarked/orange/logo.svg"
                className="h-8 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navItems.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            cn(
                              isActive
                                ? 'bg-gray-50 text-primary'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-primary',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                            )
                          }
                        >
                          {({ isActive }) => (
                            <>
                              <item.icon
                                aria-hidden="true"
                                className={cn(
                                  isActive
                                    ? 'text-primary'
                                    : 'text-gray-400 group-hover:text-primary',
                                  'h-6 w-6 shrink-0'
                                )}
                              />
                              {item.name}
                            </>
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Support
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {support.map((item) => (
                      <li key={item.id}>
                        <a
                          href={item.href}
                          onClick={
                            item.id === 'troubleshooting'
                              ? () => setTroubleshootModalOpen(true)
                              : item.action
                          }
                          className={cn(
                            item.current
                              ? 'bg-gray-50 text-primary'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-primary',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={cn(
                              item.current
                                ? 'text-primary'
                                : 'text-gray-400 group-hover:text-primary',
                              'h-6 w-6 shrink-0'
                            )}
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <Link
                    to={USING_V2 ? '/v2/settings/account' : '/settings/account'}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-primary"
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-primary"
                    />
                    Settings
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-200 lg:hidden"
            />

            <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
              {/* <form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                />
                <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Search..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                />
              </form> */}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button> */}

                {/* Separator */}
                {/* <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                /> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    {user?.profile.picture ? (
                      <img
                        alt={user.profile.name}
                        src={user.profile.picture}
                        className="h-8 w-8 rounded-full bg-gray-50"
                      />
                    ) : (
                      <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
                    )}

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      >
                        {user?.profile.name}
                      </span>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-2 h-5 w-5 text-gray-400"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        {item.action && !item.href ? (
                          <button
                            className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            onClick={() => setLogoutAlertOpen(true)}
                          >
                            {item.name}
                          </button>
                        ) : (
                          <Link
                            to={item.href!}
                            className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          >
                            {item.name}
                          </Link>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          <main className={isChatPage ? 'relative' : 'py-10'}>
            {isChatPage ? (
              <Outlet />
            ) : (
              <div className="px-4 sm:px-6 lg:px-8">
                <Outlet />
              </div>
            )}
          </main>
        </div>
      </div>

      <LogoutAlert
        isOpen={logoutAlertOpen}
        onClose={() => setLogoutAlertOpen(false)}
      />

      <TroubleshootDialog
        open={troubleshootModalOpen}
        setOpen={setTroubleshootModalOpen}
      />

      <TimeZoneChangeAlert />
    </>
  )
}
