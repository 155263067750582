import Cookies from 'js-cookie'

export const APP_NAME = 'Hupo'
export const COMPANY_NAME = 'AMI LABS PTE. LTD.'
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT || '/'
export const PROD_ORIGIN = 'https://app.hupo.co'

export const DEFAULT_COACH_TITLE = 'Coach @ Hupo'

export const ONBOARDING_EMAIL_FRIENDLY_ID = 'onboarding-email'

export const USING_PROD =
  import.meta.env.PROD && window.location.origin === PROD_ORIGIN
export const USING_V2 = Cookies.get('useV2') === '1'

export const SUPPORTED_LOCALES = ['en', 'ko', 'ru', 'ja', 'zh', 'tw']
export const DEFAULT_LOCALE = 'en'

export const DURATION_OPTIONS = [
  {
    label: '30_min',
    value: 30,
  },
  {
    label: '1_hour',
    value: 60,
  },
]
export const DURATION_LABEL_MAP: Record<number, string> = {
  30: '30_min',
  60: '1_hour',
}
export const DEFAULT_DURATION_MINS = 60
export const DEFAULT_TIMEZONE = 'Asia/Singapore'
export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const HUPO_CORE_ANDROID_APK_LOCATION_LINK =
  'https://dopmo1eihgbgm.cloudfront.net/67605b4ff20e88191d638687/app-release-1.0.71.apk'
export const HUPO_CORE_IOS_IPA_LINK =
  'https://dopmo1eihgbgm.cloudfront.net/67605dd0f20e88191d640fd0/hupo-core-0.71.zip'

export const HUPO_CORE_ANDROID_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=co.getami.core'
export const HUPO_CORE_IOS_APP_STORE_LINK =
  'https://apps.apple.com/app/ami-performance-coaching/id1638148920'

// Notification sounds
export const SEND_AUDIO_URL = 'https://puu.sh/GSHJ0/25fae22f76.mp3'
export const RECEIVE_AUDIO_URL = 'https://puu.sh/GSHIU/df806a9cb8.mp3'
export const CALL_RINGING_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-office-telephone-ring-1350.wav'
export const CALL_WAITING_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-waiting-ringtone-1354.wav'
export const CALL_JOIN_AUDIO_URL =
  'https://assets.mixkit.co/sfx/download/mixkit-magic-notification-ring-2344.wav'

export const DEFAULT_MAXIMUM_BOOKING_DAYS = 60
export const DEFAULT_CALL_TOOL = 'zoom'
export const MAX_FILE_SIZE = 1e8 // 100MB

export const firstSessionOptions = [
  'first_session_feedback_great_chemistry_with_coach',
  'first_session_feedback_clear_action_plan',
  'first_session_feedback_enjoyed_exploring_topics',
  'first_session_feedback_need_time_build_trust',
  'first_session_feedback_hope_more_structured_approach',
  'first_session_feedback_not_sure_how_best_harness_coaching',
  'other',
]

export const highOptions = [
  'five_star_feedback_discovered_smth_new_myself',
  'five_star_feedback_express_thoughts_and_supported',
  'five_star_feedback_gained_tools_for_development',
  'five_star_feedback_feel_continued_progress_towards_goals',
  'other',
]
export const midOptions = [
  'medium_rate_feedback_wants_session_more_structured',
  'medium_rate_feedback_wants_concrete_tools',
  'medium_rate_feedback_wants_continuity_sessions',
  'medium_rate_feedback_coach_doesnt_fit',
  'other',
]
export const lowOptions = [
  'low_rate_feedback_content_generic_unhelpful',
  'low_rate_feedback_no_insights_takeaways',
  'low_rate_feedback_coach_is_not_for_me',
  'low_rate_feedback_lack_direction_goals_outcomes',
  'low_rate_feedback_coach_is_not_well_versed_in_area',
  'low_rate_feedback_coaching_is_not_for_me',
  'other',
]

// Store mapping from language code to language classname for speech recognition
export const LANGUAGE_CLASSNAME_MAP: Record<string, string> = {
  en: 'en-US',
  id: 'id-ID',
  jp: 'ja-JP',
  ko: 'ko-KR',
  th: 'th-TH',
  vi: 'vi-VN',
  tw: 'cmn-Hant-TW',
  zh: 'cmn-Hans-CN',
  cmn: 'cmn-Hans-CN',
  ru: 'ru-RU',
}

export const NPS_SURVEY_OPEN_DELAY = 1500

export const CATHAY_PACIFIC_COMPANY_ID = '64b67d9a76b6b4cdbfdbc98d'
export const BAT_COMPANY_ID = '674fd3fb80286d9ddc200acb'
export const FUJIFILM_COMPANY_ID = '64e2e9425dce24c9b04deb9f'

export const COMPANY_SPECIFIC_HP_CHART_COLORS: any = {
  [CATHAY_PACIFIC_COMPANY_ID]: {
    self: '#822c42',
    manager: '#1b3668',
    peer: '#4c86a0',
    reportee: '#275c62',
  },
  [BAT_COMPANY_ID]: {
    self: '#004F9F',
    manager: '#EF7D00',
    peer: '#50AF47',
    reportee: '#5A328A',
  },
}

export const DEFAULT_HP_CHART_COLORS = {
  self: '#004F9F',
  manager: '#EF7D00',
  peer: '#50AF47',
  reportee: '#5A328A',
}

export const DEFAULT_HP_COMMENT_TAG_COLORS = {
  self: '#00B1EB',
  manager: '#EF7D00',
  peer: '#50AF47',
  reportee: '#FFBB00',
}
