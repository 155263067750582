import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { usePostHog } from 'posthog-js/react'

import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { NextButton } from './NextButton'
import { api } from '~/app/api'
import { useOnboardingContext } from '~/context/onboardingContext'
import { useE2Etest } from '~/hooks/useE2Etest'
import { saveUserToken, updateUser } from '~/features/auth/authSlice'
import { useUserDataWithAuth0Token } from '~/hooks/useUserDataWithAuth0Token'

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  const posthog = usePostHog()
  const auth0Token = useAppSelector((state) => state.auth.auth0Token)
  const { user } = useAuth0()
  const { email, coachEmail, timeZone, usesAuth0 } = useAppSelector(
    (state) => state.onboardingV2
  )
  const { currentQuestionIndex, companyId, departmentId, language } =
    useOnboardingContext()
  const isE2Etest = useE2Etest()
  const { getUserDataWithAuth0Token } = useUserDataWithAuth0Token()

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    name: string
    email: string
    password?: string
    privacyPolicy: boolean
  }>({ defaultValues: { email } })

  const { mutate } = useMutation({
    mutationFn(data: { name: string; password?: string }) {
      return api
        .url('/onboarding/profile')
        .post({
          companyId,
          departmentId,
          email,
          interfaceLanguage: language,
          language,
          name: data.name,
          password: data.password,
          preselectedCoachEmail: coachEmail.length === 0 ? null : coachEmail,
          questionIndex: currentQuestionIndex,
          timezone: timeZone,
          usesAuth0,
          auth0Id: usesAuth0 && user ? user.sub : null,
          isE2Etest,
        })
        .json<any>()
    },
    onSuccess(data) {
      dispatch(saveUserToken(data.token, 'local'))
      dispatch(updateUser(data.user))

      posthog.capture('onboarding_profile_setup', {
        questionIndex: currentQuestionIndex,
        usesAuth0,
      })

      // Make sure is user already created in our database (hence calling onSuccess)
      if (usesAuth0 && auth0Token) {
        getUserDataWithAuth0Token(auth0Token)
      }
    },
  })

  const onSubmit = handleSubmit((data) => {
    mutate({ name: data.name, password: data.password })
  })

  return (
    <>
      <form action="#" method="POST" className="space-y-6 w-4/5">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Full name
          </label>
          <div className="mt-2">
            <input
              id="name"
              type="text"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              {...register('name', { required: true })}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              type="email"
              disabled
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
              {...register('email', { required: true })}
            />
          </div>
        </div>

        {!usesAuth0 && (
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                required
                autoComplete="current-password"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                {...register('password', { required: false })}
              />
            </div>
          </div>
        )}

        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="privacy-policy"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              {...register('privacyPolicy', { required: true })}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            I agree to the{' '}
            <a
              href="https://main.hupo.co/core/user-agreement"
              target="_blank"
              rel="noreferrer noopener"
              className="font-normal leading-6 text-primary hover:text-primary hover:underline"
            >
              Hupo Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://hupo.co/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
              className="font-normal leading-6 text-primary hover:text-primary hover:underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </form>

      <NextButton className="4/5" disabled={!isValid} onClick={onSubmit} />
    </>
  )
}
