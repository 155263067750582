import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { LeadershipValueTable } from './leadershipvalue.style'
import {
  COMPANY_SPECIFIC_HP_CHART_COLORS,
  DEFAULT_HP_CHART_COLORS,
} from '../../app/constants'
import { useTranslation } from 'react-i18next'

const splitText = (text: string) => {
  const words = text.split(' ')
  const result = []
  let line: any = []
  words.forEach((word) => {
    if (line.join(' ').length + word.length > 18) {
      result.push(line.join(' '))
      line = []
    }
    line.push(word)
  })
  result.push(line.join(' '))
  return result
}

export const LeadershipValueChart = ({
  leadershipValueScores,
  showTable = false,
  chartWidth = 900,
  forPDF = false,
  company = {},
}: any) => {
  const { t } = useTranslation()
  const [series, setSeries] = useState<any>([])
  const [options, setOptions] = useState<any>({})

  useEffect(() => {
    if (!leadershipValueScores?.scores) {
      return
    }

    const series = [
      {
        name: t('self'),
        data: Object.keys(leadershipValueScores.scores.self).map((key: any) =>
          leadershipValueScores.scores.self[key]
            ? leadershipValueScores.scores.self[key].toFixed(1)
            : '-'
        ),
      },
      {
        name: t('managers_label'),
        data: Object.keys(leadershipValueScores.scores.managers).map(
          (key: any) =>
            leadershipValueScores.scores.managers[key]
              ? leadershipValueScores.scores.managers[key].toFixed(1)
              : '-'
        ),
      },
      {
        name: t('peers_label'),
        data: Object.keys(leadershipValueScores.scores.peers).map((key: any) =>
          leadershipValueScores.scores.peers[key]
            ? leadershipValueScores.scores.peers[key].toFixed(1)
            : '-'
        ),
      },
      {
        name: t('reportees_label'),
        data: Object.keys(leadershipValueScores.scores.reportees).map(
          (key: any) =>
            leadershipValueScores.scores.reportees[key]
              ? leadershipValueScores.scores.reportees[key].toFixed(1)
              : '-'
        ),
      },
    ]
    setSeries(series)

    const options = {
      chart: {
        height: 450,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      colors: Object.values(
        company._id in COMPANY_SPECIFIC_HP_CHART_COLORS
          ? COMPANY_SPECIFIC_HP_CHART_COLORS[company._id]
          : DEFAULT_HP_CHART_COLORS
      ),
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: Object.keys(leadershipValueScores.values).map((key: any) =>
          splitText(leadershipValueScores.values[key])
        ),
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return value ? value.toFixed(1) : '-'
          },
        },
        max: 6,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            enabled: true,
            position: 'top',
          },
        },
      },
    }
    setOptions(options)
  }, [leadershipValueScores, forPDF])

  return showTable ? (
    <LeadershipValueTable>
      <thead>
        <tr>
          <th>{t('values')}</th>
          <th>{t('self')}</th>
          <th>{t('managers_label')}</th>
          <th>{t('peers_label')}</th>
          <th>{t('reportees_label')}</th>
          <th>{t('range')}</th>
        </tr>
      </thead>
      <tbody>
        {leadershipValueScores &&
          Object.keys(leadershipValueScores.values).map((key) => (
            <tr key={key}>
              <td
                style={{
                  fontWeight: 600,
                  textAlign: 'left',
                }}
              >
                {leadershipValueScores.values[key]}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.self).length > 0 &&
                leadershipValueScores.scores.self[key]
                  ? parseFloat(
                      leadershipValueScores.scores.self[key].toFixed(1)
                    )
                  : '-'}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.managers).length >
                  0 && leadershipValueScores.scores.managers[key]
                  ? parseFloat(
                      leadershipValueScores.scores.managers[key].toFixed(1)
                    )
                  : '-'}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.peers).length > 0 &&
                leadershipValueScores.scores.peers[key]
                  ? parseFloat(
                      leadershipValueScores.scores.peers[key].toFixed(1)
                    )
                  : '-'}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.reportees).length >
                  0 && leadershipValueScores.scores.reportees[key]
                  ? parseFloat(
                      leadershipValueScores.scores.reportees[key].toFixed(1)
                    )
                  : '-'}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(leadershipValueScores.scores.range).length > 0 &&
                leadershipValueScores.scores.range[key]
                  ? `${leadershipValueScores.scores.range[key].min}-${leadershipValueScores.scores.range[key].max}`
                  : '-'}
              </td>
            </tr>
          ))}
      </tbody>
    </LeadershipValueTable>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width={chartWidth || 900}
    />
  )
}
