import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { api } from '~/app/api'
import { useAppSelector } from '~/app/hooks'
import { useOnboardingContext } from '~/context/onboardingContext'
import { useE2Etest } from '~/hooks/useE2Etest'
import { NextButton } from './NextButton'
import { CoachCard } from '~/components/coach-card'
import { useToast } from '@chakra-ui/react'

type Props = {
  id: string
}

export const CoachSelection: React.FC<Props> = (props) => {
  const { id } = props
  const toast = useToast()
  const { email, coachEmail } = useAppSelector((state) => state.onboardingV2)
  const {
    currentQuestionIndex,
    language,
    companyId,
    departmentId,
    postOnboardingInfo,
  } = useOnboardingContext()
  const isE2Etest = useE2Etest()
  const [selectedCoach, setSelectedCoach] = useState(coachEmail)

  const { data, isLoading } = useQuery({
    initialData: [],
    queryKey: ['onboarding', 'coach', 'options'],
    queryFn() {
      return api
        .url('/onboarding/coach_options')
        .query({
          email,
          language,
          companyId,
          departmentId,
          allowTestCoach: isE2Etest,
        })
        .get()
        .json<Response[]>()
    },
  })

  const preSelectedCoach = useMemo(
    () => data.find((d) => d.coach.email === coachEmail),
    [data, coachEmail]
  )

  const onSubmit = () => {
    if (!selectedCoach) {
      toast({
        title: 'Please select a coach',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    postOnboardingInfo({
      index: currentQuestionIndex,
      answers: { [id]: selectedCoach },
    })
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div className="w-full grid gap-4 grid-cols-1 pb-20">
        {!coachEmail &&
          data.map(({ coach, bio }) => (
            <CoachCard
              key={coach._id}
              coach={coach}
              bio={bio}
              selected={selectedCoach === coach.email}
              onSelect={setSelectedCoach}
            />
          ))}

        {coachEmail && preSelectedCoach && (
          <CoachCard
            coach={preSelectedCoach.coach}
            bio={preSelectedCoach.bio}
            selected={false}
            onSelect={setSelectedCoach}
            readOnly
          />
        )}
      </div>

      <NextButton className="fixed bottom-4 w-1/3" onClick={onSubmit} />
    </>
  )
}

interface Response {
  bio: Bio
  _id: string
  coach: Coach
  language: string
  createdAt: string
  updatedAt: string
  __v: number
  name: string
  title: any
}

interface Bio {
  summary: string
  full: string
  licenses: string[]
  background: string[]
  focusArea: FocusArea[]
  industry: Industry[]
  languages: string[]
  style: string[]
  philosophy: string
}

interface FocusArea {
  language: string
  _id: string
  focusArea: string
  friendlyID: string
  __v: number
}

interface Industry {
  language: string
  _id: string
  industry: string
  friendlyID: string
  __v: number
}

interface Coach {
  profile: Profile
  mfa: Mfa
  googleCalendar: GoogleCalendar
  notifications: Notifications
  timeFormat: string
  customers: string[]
  schedule: Schedule[][]
  maxHoursPerWeek: number
  bufferTime: number
  maxCustomers: number
  tempCustomers: any[]
  timezone: string
  language: string
  forTesting: boolean
  onboardingCompleted: boolean
  currentOnboardingStep: number
  active: boolean
  surveys: any[]
  oldPasswords: string[]
  appVersion: string
  _id: string
  email: string
  createdAt: string
  updatedAt: string
  __v: number
  location?: string
  usesNewApp?: boolean
}

interface Profile {
  name: string
  title?: string
  video?: string
  videos: Video[]
  picture: string
  businessName?: string
}

interface Video {
  _id: string
  url: string
  language: string
}

interface Mfa {
  enabled: boolean
}

interface GoogleCalendar {
  calendarList: CalendarList[]
  token?: Token
  calendarInfo?: CalendarInfo
}

interface CalendarList {
  id: string
  kind: string
  etag: string
  summary: string
  colorId: string
  timeZone: string
  selected?: string
  accessRole: string
  description?: string
  backgroundColor: string
  foregroundColor: string
  defaultReminders: DefaultReminder[]
  conferenceProperties: ConferenceProperties
  primary?: string
  notificationSettings?: NotificationSettings
  summaryOverride?: string
}

interface DefaultReminder {
  method: string
  minutes: string
}

interface ConferenceProperties {
  allowedConferenceSolutionTypes: string[]
}

interface NotificationSettings {
  notifications: Notification[]
}

interface Notification {
  type: string
  method: string
}

interface Token {
  scope: string
  token_type: string
  expiry_date: string
  access_token: string
  refresh_token: string
}

interface CalendarInfo {
  id: string
  kind: string
  etag: string
  summary: string
  colorId: string
  primary: string
  timeZone: string
  selected: string
  accessRole: string
  backgroundColor: string
  foregroundColor: string
  defaultReminders: DefaultReminder2[]
  notificationSettings: NotificationSettings2
  conferenceProperties: ConferenceProperties2
}

interface DefaultReminder2 {
  method: string
  minutes: string
}

export interface NotificationSettings2 {
  notifications: Notification2[]
}

export interface Notification2 {
  type: string
  method: string
}

export interface ConferenceProperties2 {
  allowedConferenceSolutionTypes: string[]
}

export interface Notifications {
  email: Email
}

export interface Email {
  booking: boolean
  cancellation: boolean
  reschedule: boolean
  reminder: boolean
  followUp: boolean
}

export interface Schedule {
  end: string
  start: string
}
