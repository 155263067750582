import { Box, Flex, HStack, Link, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import {
  leadershipValueScaleSelf,
  leadershipValueScaleSelfFujifilm,
} from '../../../utils/ratings'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import { setRating } from '../assessmentSlice'
import { useAuth } from '~/hooks/useAuth'
import { FUJIFILM_COMPANY_ID } from '~/app/constants'

const SelectOption: React.FC<any> = (props) => {
  const { rating, selected, onItemSelected } = props
  const isItemSelected = selected?.scale === rating.scale
  return (
    <Box
      key={rating.level}
      boxShadow="0 0px 5px rgba(0,0,0,0.15)"
      mb={5}
      borderRadius={20}
      padding={15}
      bg={isItemSelected ? 'var(--chakra-colors-primary-400)' : '#fff'}
      cursor="pointer"
      onClick={() => onItemSelected(rating)}
    >
      <Flex direction={'column'} alignItems="center">
        <Text
          color={isItemSelected ? '#fff' : '#000'}
          textAlign="center"
          fontWeight="600"
          fontSize={'xl'}
          // mb={3}
        >
          {rating.level}
        </Text>
      </Flex>
    </Box>
  )
}

const ValueQuestion: React.FC<any> = (props) => {
  const { progress, question, onSubmit, leadershipValue } = props
  const [selected, setSelected] = useState<any>(null)
  const dispatch = useDispatch()
  const { t } = useLocale()
  const user = useSelector((state: RootState) => state.assessment.user)
  const toast = useToast()
  const [showDescription, setShowDescription] = useState(false)
  const selectedRating = useSelector(
    (state: RootState) =>
      state.assessment.ratings[leadershipValue && leadershipValue.value]
  )
  const {
    user: { company },
  } = useAuth() as any

  const onItemSelected = (rating: any) => {
    setSelected(rating)
    dispatch(
      setRating({
        focusArea: leadershipValue.value,
        scale: rating.scale,
      })
    )
  }

  const onLocalSubmit = () => {
    if (!selected) {
      toast({
        status: 'error',
        title: 'Error',
        description: t('select_option'),
        isClosable: true,
      })
      return
    }
    onSubmit()
  }

  const valueScaleInstance =
    company._id === FUJIFILM_COMPANY_ID
      ? leadershipValueScaleSelfFujifilm(t)
      : leadershipValueScaleSelf(t)

  useEffect(() => {
    if (selectedRating) {
      const selectedRatingScale: any = valueScaleInstance.find(
        (rating) => rating.scale === selectedRating
      )
      if (selected?.scale !== selectedRatingScale?.scale) {
        setSelected(selectedRatingScale)
      }
    } else {
      setSelected(null)
    }
  }, [selectedRating, valueScaleInstance])

  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title.replaceAll('{{username}}', user)}
          description={
            <>
              <Text textAlign="center" fontSize={'xl'} fontStyle="italic">
                {leadershipValue.label}
              </Text>
              {!!leadershipValue.descriptionHtml && (
                <>
                  <Link onClick={() => setShowDescription(!showDescription)}>
                    <div style={{ textAlign: 'center' }}>
                      <HStack>
                        <Text textAlign="center" as="u">
                          {showDescription
                            ? t('hide_description')
                            : t('show_description')}
                        </Text>
                        {showDescription ? (
                          <MdExpandLess size={24} />
                        ) : (
                          <MdExpandMore size={24} />
                        )}
                      </HStack>
                    </div>
                  </Link>
                  {showDescription ? (
                    <div
                      style={{ fontSize: '1.em' }}
                      dangerouslySetInnerHTML={{
                        __html: leadershipValue.descriptionHtml,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          }
        />
        <Box my={3}>
          {valueScaleInstance.map((rating) => (
            <SelectOption
              key={'rating#' + rating.scale}
              rating={rating}
              selected={selected}
              onItemSelected={onItemSelected}
            />
          ))}
        </Box>
        <OnboardingFooter onSubmit={onLocalSubmit} submitText={t('next')} />
      </OnboardingContainer>
    </>
  )
}

export default ValueQuestion
