/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetLeadershipValueScoresForReportQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import { LeadershipValueChart } from '../hupoperspectives/LeadershipValueChart'
import { useHideChatlio } from '../../hooks/useHideChatlio'

const AssessmentReport = () => {
  const { userId } = useParams<{ userId: string }>()
  const { t } = useLocale()
  const {
    data: leadershipValueScoresReport,
    isLoading,
    error,
  } = useGetLeadershipValueScoresForReportQuery(userId)
  const [currentPage, setCurrentPage] = useState(0)

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage))
  }

  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      prevPage < pages.length - 1 ? prevPage + 1 : prevPage
    )
  }

  useHideChatlio()

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'ArrowLeft') {
        handlePrevClick()
      } else if (event.key === 'ArrowRight') {
        handleNextClick()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.toString()}</div>
  }

  const {
    user,
    coach,
    scores,
    answers,
    valuesToFocus,
    recommendations,
    values,
    groupedValues,
    respondentStats,
    selfAnswers,
    date,
    company,
  } = leadershipValueScoresReport

  console.log('Scores: ', scores)

  const pages = [
    <Page1 key="page1" user={user} coach={coach} date={date} />,
    <Page2
      key="page2"
      user={user}
      selfAnswers={selfAnswers}
      values={values}
      respondentStats={respondentStats}
    />,
    <Page3
      key="page3"
      user={user}
      scores={scores}
      values={values}
      groupedValues={groupedValues['leading_self']}
      title={t('leading_self')}
      company={company}
    />,
    <Page3
      key="page4"
      user={user}
      scores={scores}
      values={values}
      groupedValues={groupedValues['leading_others']}
      title={t('leading_others')}
      company={company}
    />,
    <Page3
      key="page5"
      user={user}
      scores={scores}
      values={values}
      groupedValues={groupedValues['leading_organization']}
      title={t('leading_organization')}
      company={company}
    />,
    <Page6
      key="page6"
      user={user}
      valuesToFocus={valuesToFocus}
      recommendations={recommendations}
    />,
    <Page7 key="page7" user={user} answers={answers} />,
    <Page8 key="page8" user={user} answers={answers} />,
  ]

  return (
    <Box bg="#EAEDEF" minHeight="100vh">
      <Grid templateColumns={['1fr', '3fr 6fr 3fr']} gap={8} p={8}>
        <Box display={['none', 'block']} />
        <Box>
          <Flex justify="space-between" align="center" mb={8}>
            <Image
              src="https://d11dx3d8ky7l52.cloudfront.net/images/cathay_logo.png"
              alt="Cathay Pacific Logo"
              height="40px"
            />
            <Logo height="40px" width="auto" />
          </Flex>

          {pages[currentPage]}

          <Flex justify="space-between" mt={8} alignItems="center">
            <Button onClick={handlePrevClick} isDisabled={currentPage === 0}>
              {t('prev')}
            </Button>
            <Text fontWeight="bold">{t('page', { currentPage })}</Text>
            <Button
              onClick={handleNextClick}
              isDisabled={currentPage === pages.length - 1}
            >
              {t('next')}
            </Button>
          </Flex>
        </Box>
        <Box display={['none', 'block']} backgroundColor="#EAEDEF" />
      </Grid>
    </Box>
  )
}

const Page1 = ({ user, date }: any) => {
  const formattedDate = new Date(date).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  })

  return (
    <Flex>
      <Box flex={1} mr={[0, 8]}>
        <Heading size="md" mb={4}>
          {formattedDate}
        </Heading>
        <Heading size="lg" color="#FF4B0A" mb={2}>
          {user.name}
        </Heading>
        <Text mb={4} fontSize="lg" fontWeight="bold">
          Lite-360 Peer reflections & feedback
        </Text>

        <Text mb={4}>
          This report contains the collated feedback from several of your
          colleagues as well as your own reflections. It's intended to support
          your personal growth and ongoing development as a leader.
        </Text>

        <Text mb={4}>
          When reviewing the content, please remember that you have asked your
          colleagues for their honest, frank feedback about how they perceive
          your behaviour, in relation to the Leadership DNA and Values set forth
          and championed by your organisation. Internalize the feedback, holding
          both positive and negative comments in balance.
        </Text>

        <Text>
          Pay attention to the broader themes in the feedback, as well as
          general trends, and even discrepancies. Different perceptions are
          normal, since different stakeholders see you in different contexts.
          Ask yourself: "why might different individuals perceive me
          differently?" Let's dive in.
        </Text>
      </Box>

      <Box flex={1}>
        <Image
          src="https://d11dx3d8ky7l52.cloudfront.net/images/report_main.webp"
          alt="Group meeting"
          mb={8}
        />

        {/* <Flex align="center">
          <Image
            src={coach.avatar}
            alt="Coach"
            boxSize={16}
            borderRadius="full"
            mr={4}
          />
          <Box>
            <Text size="sm" color="gray.500" mb={2}>
              Your Coach
            </Text>
            <Heading size="sm">{coach.name}</Heading>
            <Text>{coach.licenses}</Text>
            <Text>Languages: {coach.languages.join(', ')}</Text>
          </Box>
        </Flex> */}
      </Box>
    </Flex>
  )
}

const Page2 = ({ user, selfAnswers, values, respondentStats }: any) => (
  <Grid templateColumns={['1fr', '1fr 1fr']} gap={8}>
    <Box>
      <ReportHeading user={user} />

      <Heading size="sm" mt={8} mb={4}>
        <span>Leadership Values</span>
      </Heading>
      <Text mb={4} sx={{ fontStyle: 'italic' }}>
        Based on the set of Leadership Values defined by the Cathay Pacific, and
        co-designed with Hupo.
      </Text>

      <Box mb={8}>
        <UnorderedList spacing={2} fontWeight="bold">
          {Object.values(values).map((value: any, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </UnorderedList>
      </Box>

      <Text fontWeight="bold" mb={2}>
        Reviewed by:
      </Text>
      <UnorderedList>
        <ListItem>
          Manager {respondentStats.managers.reviewed}/
          {respondentStats.managers.total}
        </ListItem>
        <ListItem>
          Peers {respondentStats.peers.reviewed}/{respondentStats.peers.total}
        </ListItem>
        <ListItem>
          Direct Reports {respondentStats.reportees.reviewed}/
          {respondentStats.reportees.total}
        </ListItem>
      </UnorderedList>
    </Box>

    <Box>
      {selfAnswers.map((answer: any, index: any) => (
        <Box key={index} mb={8}>
          <Heading size="sm" mb={4}>
            {answer.question}
          </Heading>
          <Text>{answer.responses[0]}</Text>
        </Box>
      ))}
    </Box>
  </Grid>
)

interface Page3Props {
  user: any
  company: any
  scores: {
    [key: string]: {
      [key: string]: number
    }
  }
  values: {
    [key: string]: string
  }
  groupedValues: string[]
  title: string
}

const Page3 = ({
  user,
  company,
  scores,
  values,
  groupedValues,
  title,
}: Page3Props) => {
  const data = useMemo(() => {
    const filteredScores = Object.entries(scores).reduce(
      (acc, [key, value]) => {
        acc[key] = Object.entries(value).reduce(
          (scoreAcc, [scoreKey, scoreValue]) => {
            if (groupedValues.includes(scoreKey)) {
              scoreAcc[scoreKey] = scoreValue
            }
            return scoreAcc
          },
          {} as { [key: string]: number }
        )
        return acc
      },
      {} as { [key: string]: { [key: string]: number } }
    )

    const filteredValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (groupedValues.includes(key)) {
          acc[key] = value
        }
        return acc
      },
      {} as { [key: string]: string }
    )

    return {
      scores: filteredScores,
      values: filteredValues,
    }
  }, [scores, values, groupedValues])

  return (
    <Box>
      <Grid templateColumns={['1fr', '1fr 1fr']} gap={8}>
        <Box minWidth={250}>
          <ReportHeading user={user} mb={10} />
          <Box mb={8}>
            <Text mb={2} fontWeight="bold">
              Legend:
            </Text>
            <OrderedList spacing={2}>
              <ListItem>N.A. (No opportunity)</ListItem>
              <ListItem>Rarely seen</ListItem>
              <ListItem>Seen a few times</ListItem>
              <ListItem>Seen numerous times</ListItem>
              <ListItem>Consistently demonstrates</ListItem>
              <ListItem>Admired as a role model</ListItem>
            </OrderedList>
          </Box>
        </Box>
        <Box maxW={600}>
          <Heading size="md" mb={8}>
            {title}
          </Heading>
          <LeadershipValueChart
            leadershipValueScores={data}
            showTable={false}
            chartWidth={600}
            company={company}
          />
          <LeadershipValueChart
            leadershipValueScores={data}
            showTable={true}
            company={company}
          />
        </Box>
      </Grid>
    </Box>
  )
}

const Page6 = ({ user, valuesToFocus, recommendations }: any) => (
  <Grid templateColumns={['1fr', '1fr 1fr']} gap={8}>
    <Box>
      <ReportHeading user={user} mb={8} />

      <Heading size="md" mb={4} mt={8}>
        <span>Specific suggestions and feedback from trusted colleagues</span>
      </Heading>

      <Text mb={4}>
        The key topics addressed were related to the leadership focus areas of:
      </Text>

      {valuesToFocus ? (
        <UnorderedList spacing={2} mb={8} sx={{ fontStyle: 'italic' }}>
          {valuesToFocus.map(({ value, label, count }: any) => (
            <ListItem key={value}>
              {label} (x{count})
            </ListItem>
          ))}
        </UnorderedList>
      ) : (
        <Text>No responses available.</Text>
      )}
    </Box>

    <Box>
      <Text mb={8} fontSize="lg" fontWeight="bold">
        {user.name} can consider some of these perspectives and practical
        recommendations on what and how to improve as a leader.
      </Text>

      <Box mb={4}>
        <Heading size="sm" mb={2}>
          MANAGER
        </Heading>
        {recommendations.manager ? (
          <UnorderedList spacing={2}>
            {recommendations.manager.map((recommendation: any, index: any) => (
              <ListItem key={index}>{recommendation}</ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No responses available.</Text>
        )}
      </Box>

      <Box mb={4}>
        <Heading size="sm" mb={2}>
          PEERS
        </Heading>
        {recommendations.peer ? (
          <UnorderedList spacing={2}>
            {recommendations.peer.map((recommendation: any, index: any) => (
              <ListItem key={index}>{recommendation}</ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No responses available.</Text>
        )}
      </Box>

      <Box>
        <Heading size="sm" mb={2}>
          DIRECT REPORTS
        </Heading>
        {recommendations.reportee ? (
          <UnorderedList spacing={2}>
            {recommendations.reportee.map((recommendation: any, index: any) => (
              <ListItem key={index}>{recommendation}</ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No responses available.</Text>
        )}
      </Box>
    </Box>
  </Grid>
)

interface Answer {
  question: string
  questionID: string
  responses: {
    managers: string[]
    peers: string[]
    reportees: string[]
  }
}

interface Page7Props {
  user: {
    name: string
  }
  answers: Answer[]
}

const Page7 = ({ user, answers }: Page7Props) => {
  const strengths: any =
    answers.find(
      (answer) => answer.questionID === 'what-are-username-strengths'
    )?.responses || {}

  if (!strengths) {
    return <div>No responses available.</div>
  }

  return (
    <Box>
      <Grid templateColumns={['1fr', '1fr 1fr']} gap={8}>
        <Box>
          <ReportHeading user={user} />
        </Box>
        <AdditionalCommentsBox>
          What does {user.name} do really well & should continue to do?
          <br />
          What are {user.name}’s strengths?
        </AdditionalCommentsBox>
      </Grid>
      <Box>
        <Box mb={8}>
          <Heading size="sm" mb={2}>
            MANAGER
          </Heading>
          <UnorderedList spacing={2}>
            {strengths.managers?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
        <Box mb={8}>
          <Heading size="sm" mb={2}>
            PEERS
          </Heading>
          <UnorderedList spacing={2}>
            {strengths.peers?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            DIRECT REPORTS
          </Heading>
          <UnorderedList spacing={2}>
            {strengths.reportees?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
      </Box>
    </Box>
  )
}

interface Page8Props {
  user: {
    name: string
  }
  answers: Answer[]
}

const Page8 = ({ user, answers }: Page8Props) => {
  const areasToImprove: any =
    Object.values(answers).find(
      (answer) => answer.questionID === 'what-username-to-develop-further'
    )?.responses || {}

  if (!areasToImprove) {
    return <div>No responses available.</div>
  }

  return (
    <Box>
      <Grid templateColumns={['1fr', '1fr 1fr']} gap={8}>
        <Box>
          <ReportHeading user={user} mb={8} />
        </Box>
        <AdditionalCommentsBox>
          What should {user.name} do more of?
          <br />
          What could {user.name} develop further?
        </AdditionalCommentsBox>
      </Grid>
      <Box>
        <Box mb={8}>
          <Heading size="sm" mb={2}>
            MANAGER
          </Heading>
          <UnorderedList spacing={2}>
            {areasToImprove.managers?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
        <Box mb={8}>
          <Heading size="sm" mb={2}>
            PEERS
          </Heading>
          <UnorderedList spacing={2}>
            {areasToImprove.peers?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            DIRECT REPORTS
          </Heading>
          <UnorderedList spacing={2}>
            {areasToImprove.reportees?.map((response: any, index: any) => (
              <ListItem key={index}>{response}</ListItem>
            )) || <ListItem>No responses available.</ListItem>}
          </UnorderedList>
        </Box>
      </Box>
    </Box>
  )
}

const ReportHeading = ({ user, ...props }: any) => (
  <Box {...props}>
    <Heading size="md" mb={4}>
      PEER INPUTS -{' '}
      {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
    </Heading>

    <Heading size="lg" color="#FF4B0A" mb={2}>
      {user.name}
    </Heading>
    <Text mb={2} fontWeight="bold">
      Role: {user.role}
    </Text>
  </Box>
)

const AdditionalCommentsBox = (props: any) => (
  <Box mb={8} textAlign="right" {...props}>
    <Heading size="md" mb={4}>
      Additional comments
    </Heading>
    <Text mb={4} sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
      {props.children}
    </Text>
  </Box>
)

export default AssessmentReport
