import {
  Avatar,
  Box,
  Divider,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { processNewLineText } from '../../utils/text'
import { useParams } from 'react-router-dom'

const ExpandableText: FC<any> = ({ title, text }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(true)

  if (!text) {
    return null
  }

  return (
    <>
      <Box>
        <Text fontWeight="700" fontSize="18px" mb={2}>
          {title}
        </Text>
        <Text noOfLines={visible ? 3 : undefined}>
          {text &&
            processNewLineText(text)
              .split('\n')
              .map((str: string) => (
                <>
                  {str}
                  <br />
                </>
              ))}
        </Text>
        <Text
          color="var(--chakra-colors-primary-500)"
          py={2}
          fontWeight="400"
          display="inline-block"
          cursor="pointer"
          onClick={() => setVisible(!visible)}
          userSelect="none"
        >
          {t(visible ? 'read_more' : 'read_less')}
        </Text>
      </Box>
      <Divider my={4} />
    </>
  )
}

const CoachInfo: FC<any> = ({ isOpen, onClose, coach }) => {
  const { t } = useTranslation()
  const { language } = useParams()

  let bio = coach?.bio || coach?.coachBio

  if (!bio) {
    bio = bio?.bio
  }
  const profile = coach?.profile || coach?.coach?.profile

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {coach && (
        <ModalContent>
          <ModalHeader
            style={{
              padding: '0',
              position: 'sticky',
              backgroundColor: '#fff',
              zIndex: 2,
              top: 0,
              borderRadius: '10px 10px 0 0',
            }}
            shadow="sm"
          >
            <Flex p={4} alignItems="center" gridColumnGap={3}>
              <Avatar size="lg" src={profile?.picture} />
              <Flex direction="column" alignItems="flex-start">
                <Text fontWeight="700">{profile.name} </Text>
                <Text fontSize="14px">{profile.title} </Text>
              </Flex>
            </Flex>
            <Divider />
            <ModalCloseButton zIndex={3} />
          </ModalHeader>
          <ModalBody pb={4}>
            {bio.full && bio.full.length > 0 && (
              <ExpandableText title={t('biography')} text={bio.full} />
            )}
            {bio.philosophy && bio.philosophy.length > 0 && (
              <ExpandableText
                key="philosophy"
                title={t('coaching_philosophy')}
                text={bio.philosophy}
              />
            )}
            {bio.licenses.length > 0 && (
              <>
                <Box>
                  <Text fontWeight="700" fontSize="18px" mb={2}>
                    {t('professional_licenses')}
                  </Text>
                  <UnorderedList>
                    {bio.licenses.map((license: string, index: number) => (
                      <ListItem key={`license_${index}`}>
                        <Text> {processNewLineText(license)} </Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Divider my={4} />
              </>
            )}
            {bio.background.length > 0 && (
              <>
                <Box>
                  <Text fontWeight="700" fontSize="18px" mb={2}>
                    {t('professional_background')}
                  </Text>
                  <UnorderedList>
                    {bio.background.map((bg: string, index: number) => (
                      <ListItem key={`bg_${index}`}>
                        <Text> {processNewLineText(bg)} </Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Divider my={4} />
              </>
            )}
            {bio.industry.length > 0 && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('industry_expertise')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {bio.industry.map((item: any) => (
                    <Tag size="lg" colorScheme="green" key={item._id}>
                      <TagLabel> {item.industry} </TagLabel>
                    </Tag>
                  ))}
                </Flex>
                <Divider my={4} />
              </Box>
            )}
            {bio.focusArea.length > 0 && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('focus_areas')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {bio.focusArea.map((item: any) => (
                    <Tag size="lg" colorScheme="green" key={item._id}>
                      <TagLabel> {item.focusArea} </TagLabel>
                    </Tag>
                  ))}
                </Flex>
                <Divider my={4} />
              </Box>
            )}
            {bio.languages.length > 0 && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('languages')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {bio.languages.map((item: string) => (
                    <Tag size="lg" colorScheme="green" key={item}>
                      <TagLabel> {item} </TagLabel>
                    </Tag>
                  ))}
                </Flex>
                <Divider my={4} />
              </Box>
            )}
            {bio.style.length > 0 && (
              <Box>
                <Text fontWeight="700" fontSize="18px" mb={2}>
                  {t('coaching_style')}
                </Text>
                <Flex
                  wrap="wrap"
                  gridGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {bio.style.map((item: string) => (
                    <Tag size="lg" colorScheme="green" key={item}>
                      <TagLabel> {item} </TagLabel>
                    </Tag>
                  ))}
                </Flex>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  )
}

export default CoachInfo
